/* Leaving this empty we can add custom css as needed */
/* The css comes from ADS */

* {
  @apply font-gotham;
}

.custom-radio {
  appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  outline: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  border: 0.2px solid #a5b7bf;
}

.custom-radio:checked {
  background-color: #0d9283;
}

.custom-radio:hover {
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}
